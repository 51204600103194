// const SettingsPaage=()=>{
//     return(
//         <>
//         <h1>SettingsPaage</h1>
//         </>
//     )
// }

// export default SettingsPaage

const SettingsPaage=()=>{
    return(
        <>
        <h1>Virtual Screening</h1>
        </>
    )
}

export default SettingsPaage