import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaInfoCircle, FaTimes, FaChevronDown, FaFilter, FaChevronUp, FaExternalLinkAlt, FaSmile, FaFlask, FaPuzzlePiece, FaCog, FaCogs } from 'react-icons/fa';
import Loader from '../Loader';
import MolportAPI from './MolportAPI';
import jsPDF from 'jspdf';
import SmileViewer from '../smileViewer';
import { FiCopy, FiDownload } from 'react-icons/fi';
import { IoSearchOutline } from 'react-icons/io5';
import axios from 'axios';
import { ArrowLeft, Info,X } from 'lucide-react';
import '../../styles/editor.css'
 
//  import { Tooltip } from "@/components/ui/tooltip";
 
function RetroVendorScreen() {
 
  const smilesRef = useRef(null)
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedSupplier, setSelectedSupplier] = useState('chemspace');
  const [isSupplierChanging, setIsSupplierChanging] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [activeTab, setActiveTab] = useState('Exact Search');
  const [showProperties, setShowProperties] = useState(null);
  const [expandedVendor, setExpandedVendor] = useState(null);
  const [showPropertiesDialog, setShowPropertiesDialog] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState(null);
  const [selectedSmiles , setSelectedSmiles] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState({
    vendors: [],
    leadTimes: [],
    prices: [],
    routeTypes: [],
  });
  const [temporaryFilters, setTemporaryFilters] = useState({
    vendors: [],
    leadTimes: [],
    prices: [],
    routeTypes: [],
  });
  const [loading, setLoading] = useState(false);
 
  const [smilesData, setSmilesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
 
 
  const [isFilterVisible, setIsFilterVisible] = useState(false);
 
  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
 
  const handlePropertiesClick = (properties,smiles) => {
    setSelectedProperties(properties);
    setShowPropertiesDialog(true);
    setSelectedSmiles(smiles)
  };
 
  const handleCopySmiles = (smiles) => {
    navigator.clipboard.writeText(smiles);
    alert('SMILES copied to clipboard!');
  };
 
 
  useEffect(() => {
    if (location.state?.reactantString) {
      setInputValue(location.state.reactantString);
    }
  }, [location.state]);
  useEffect(() => {
    if (location.state?.smile) {
      setInputValue(location.state.smile);
    }
  }, [location.state]);
 
 
  // Add this state
  const [activeCategory, setActiveCategory] = useState('Screening Compounds');
 
  // Modify the useEffect for API calls
  useEffect(() => {
    if (inputValue) {
      setLoading(true);
      const fetchVendorData = async () => {
        try {
          const baseUrl = 'https://caitapimus.azure-api.net/retro-uat/vendor/chemspace';
          const headers = {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': 'e3ad0fb3039a48ba840ce271ef718c82',
          };
 
          // Map the search type to correct API endpoint
          let searchType = '';
          switch (activeTab) {
            case 'Substructure Search':
              searchType = 'sub-search';
              break;
            case 'Similarity Search':
              searchType = 'similarity-search';
              break;
            case 'Exact Search':
              searchType = 'exact-search';
              break;
            default:
              searchType = 'exact-search';
          }
 
          // Map the category to correct API endpoint
          let category = '';
          switch (activeCategory) {
            case 'Screening Compounds':
              category = 'screening-compounds';
              break;
            case 'Building Blocks':
              category = 'building-blocks';
              break;
            case 'Custom Synthesis':
              category = 'custom-synthesis';
              break;
            default:
              category = 'screening-compounds';
          }
 
          const url = `${baseUrl}/${searchType}/${category}`;
          console.log('Requesting URL:', url); // For debugging
 
          const requestBody = {
            shipToCountry: 'US',
            smile: inputValue
          };
 
          const response = await axios.post(url, requestBody, { headers });
 
          const formattedData = (response.data.items || []).map(item => ({
            smiles: item.smiles || '',
            csId: item.csId || '',
            link: item.link || '',
            properties: item.properties || {},
            vendors: Array.isArray(item.offers) ? item.offers.map((offer) => ({
              vendorName: offer.vendorName || 'Unknown',
              leadTime: offer.leadTimeDays || 'N/A',
              prices: Array.isArray(offer.prices) ? offer.prices : [],
            })) : [],
            type: category
          }));
 
          setSmilesData(formattedData);
          setFilteredData(formattedData);
        } catch (error) {
          console.error('Error fetching data:', error);
          setSmilesData([]);
          setFilteredData([]);
        } finally {
          setLoading(false);
        }
      };
 
      fetchVendorData();
    }
  }, [inputValue, activeTab, activeCategory, selectedSupplier]);
 
 
 
  useEffect(() => {
  }, [selectedFilters, smilesData]);
 
 
 
  const handleSupplierChange = (e) => {
    setIsSupplierChanging(true);
    setSelectedSupplier(e.target.value);
    // Clear data when switching suppliers
    setSmilesData([]);
    setFilteredData([]);
    setIsSupplierChanging(false);
  };
 
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
 
  const buttonNames = ['Exact Search', 'Similarity Search', 'Substructure Search'];
 
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
 
  const toggleVendorDropdown = (index) => {
    setExpandedVendor(expandedVendor === index ? null : index);
  };
 
  const toggleProperties = (index) => {
    setShowProperties(showProperties === index ? null : index);
  };
 
  const handleCheckboxChange = (category, option) => {
    setTemporaryFilters(prevState => {
      const updatedCategory = prevState[category].includes(option)
        ? prevState[category].filter(item => item !== option)
        : [...prevState[category], option];
 
      return {
        ...prevState,
        [category]: updatedCategory,
      };
    });
  };
 
 
 
  useEffect(() => {
 
    if (isAnyFilterSelected()) {
      applyFilters();
    } else {
      setFilteredData(smilesData);
    }
  }, [temporaryFilters, smilesData]);
 
  const isAnyFilterSelected = () => {
    return Object.values(temporaryFilters).some(filterArray => filterArray.length > 0);
  };
 
 
  const applyFilters = () => {
    let filtered = smilesData;
 
    if (temporaryFilters.vendors.length > 0) {
      filtered = filtered.filter(item =>
        item.vendors.some(vendor => temporaryFilters.vendors.includes(vendor.vendorName))
      );
    }
 
    if (temporaryFilters.prices.length > 0) {
      filtered = filtered.filter(item =>
        item.vendors.some(vendor =>
          vendor.prices.some(price =>
            temporaryFilters.prices.includes(`${price.priceUsd || 'N/A'} USD / ${price.priceEur || 'N/A'} EUR`)
          )
        )
      );
    }
 
    if (temporaryFilters.leadTimes.length > 0) {
      filtered = filtered.filter(item =>
        item.vendors.some(vendor =>
          temporaryFilters.leadTimes.includes(`${vendor.leadTime} days`)
        )
      );
    }
 
    setFilteredData(filtered);
  };
 
  const handleBackClick = () => {
    navigate(-1); // This will go back to the previous page
  };
  return (
    <div >
 
      {loading && (
        <div className="fixed inset-0  backdrop-blur-sm  bg-opacity-50 z-50 flex items-center justify-center">
          <Loader />
        </div>
      )}
 
      <div>
        <button onClick={handleBackClick} className="back-button mt-6 ml-8 flex items-center text-dark">
          <ArrowLeft height={20} width={20} style={{ marginRight: '5px' }} /> {/* Add left arrow icon */}
          Back
        </button>
      </div>
 
      <div className="flex flex-wrap gap-4 p-10  font-segoe">
        <div className="flex items-center gap-4 mb-4 w-full">
          <input
            type="text"
            placeholder="Enter SMILES"
            value={inputValue}
            onChange={handleInputChange}
            className="flex-1 h-[40px] bg-white shadow-sm border rounded-lg px-2"
          />
          <button className="w-[125px] border border-[#707070] h-[38px] bg-[#735AA7] hover:bg-[#863FC4] shadow-md text-white rounded-lg flex items-center justify-center space-x-2">
            <span>Search</span>
            <IoSearchOutline size={22} />
          </button>
        </div>
        <div className="flex justify-between   w-full mb-4">
          {buttonNames.map((name, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(name)}
              className={`flex-1 h-[37px] rounded-md mx-2 last:ml-auto ${activeTab === name ? 'bg-[#2193D7] text-white' : 'bg-white text-black border'
                }`}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
 
      <div className="flex items-center justify-end space-x-4 mr-[1%]">
        <select
          value={selectedSupplier}
          onChange={handleSupplierChange}
          className="bg-white hover:bg-gray-200 hover:text-dark font-semibold shadow-md border border-gray-300 rounded-md px-3 py-2"
        >
          <option value="chemspace">Chemspace</option>
          <option value="molport">Molport</option>
        </select>
        {/* <button
          onClick={toggleFilterVisibility}
          className="bg-[#735AA7] text-white py-2 shadow-md px-4 rounded flex items-center"
        >
          {isFilterVisible ? 'Close Filter' : 'Filter'}
          {isFilterVisible ? (
            <FaTimes className="inline ml-2" />
          ) : (
            <FaFilter className="inline ml-2" />
          )}
        </button> */}
      </div>
 
      {isFilterVisible && (
        <div className="w-full mx-10 mt-6 max-w-[1200px] h-[338px] border border-black opacity-100 rounded-lg  overflow-hidden">
          <div className="w-full h-[48px] bg-[#4A5568] rounded-t-lg flex items-start text-left p-2 text-white font-semibold">
            Select Filters
          </div>
          <div className="flex w-[150%] h-[100%] overflow-x-scroll">
            {[{
              label: "Vendor",
              options: [...new Set(smilesData.flatMap(item => item.vendors.map(v => v.vendorName)))],
              category: 'vendors',
            }, {
              label: "Lead Time",
              options: [...new Set(smilesData.flatMap(item => item.vendors.map(v => `${v.leadTime} days`)))],
              category: 'leadTimes',
            }, {
              label: "Price (USD / EUR)",
              options: [...new Set(smilesData.flatMap(item => item.vendors.flatMap(v => v.prices.map(p => `${p.priceUsd || 'N/A'} USD / ${p.priceEur || 'N/A'} EUR`))))],
              category: 'prices',
            }, {
              label: "Route Type",
              options: ["1-Step Route", "2-Step Route"],
              category: 'routeTypes',
            }].map((section, index) => (
              <div
                key={index}
                className={`w-1/4 p-4 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} border border-gray-300 overflow-y-scroll`}
              >
                <div className="flex justify-between items-center">
                  <label className="block font-semibold text-gray-700">{section.label}</label>
                  <button className="text-sm text-blue-500">Clear</button>
                </div>
                <div className="mt-2 space-y-2 overflow-y-scroll">
                  {section.options.map((option, i) => (
                    <label key={i} className="block">
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={() => handleCheckboxChange(section.category, option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedSupplier === 'chemspace' && (
        <div className="ml-4 font-segoe">
          <div className="grid grid-cols-1 gap-4">
            {/* Categories Tabs with Icons */}
            <div className="flex space-x-6 mb-6 ml-6">
              {['Screening Compounds', 'Building Blocks', 'Custom Synthesis'].map((category, index) => (
                <div
                  key={category}
                  onClick={() => setActiveCategory(category)}
                  className={`cursor-pointer px-6 py-3 rounded-lg transition-all duration-300
        ${activeCategory === category
                      ? 'bg-[#735DA8] text-white transform scale-105'
                      : 'bg-white text-gray-700 border border-gray-300 hover:bg-[#8A7DB8] hover:text-white'
                    }`}
                >
                  <div className="flex items-center space-x-2">
                    <span className="font-semibold">{category}</span>
                    {/* Add icons based on the category */}
                    <div className="text-lg">
                      {index === 0 && <FaFlask size={24} />} {/* Icon for Screening Compounds */}
                      {index === 1 && <FaPuzzlePiece size={24} />} {/* Icon for Building Blocks */}
                      {index === 2 && <FaCogs size={24} />} {/* Icon for Custom Synthesis */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
 
 
 
            {/* Results Table */}
            <div className="col-span-4 ml-2 overflow-hidden">
              <div className="flex">
                {/* Conditionally render the Fixed Input Structure Column if data is available */}
                {filteredData.length > 0 && (
                  <div className="sticky left-0 top-0 bg-white z-10">
                    <table className="min-w-full bg-white rounded-lg ">
                      <thead>
                        <tr>
                          <th className="px-36 py-3 text-center text-sm font-semibold text-white bg-[#4A5568]">
                            Input Structure
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="px-6 sticky left-0 z-10 h-auto">
                            <div className="flex justify-center">
                              <div className="transition-transform mt-[10%] duration-300 hover:scale-150">
                                <SmileViewer smileString={inputValue} imgwidth={200} imgheight={200} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
 
                {/* Scrollable Result Structure and Details Columns */}
                <div className="overflow-x-auto overflow-y-auto max-h-[30rem] flex-1 scrollbar-default">
                  {/* Show the table only if there is data */}
                  {filteredData.length > 0 ? (
                    <table className="min-w-full border-2 border-[#CBD5E0] bg-white rounded-lg shadow-lg">
                      <thead className="bg-[#4A5568] sticky top-0 z-10">
                        <tr>
                          <th className="px-6 py-3 text-center text-sm font-semibold text-white border-r">
                            Result Structure
                          </th>
                          <th className="px-6 py-3 text-center text-sm font-semibold text-white">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item, index) => (
                          <tr key={index} className="hover:bg-white border-b">
                            {/* Scrollable Result Structure Column */}
                            <td className="px-6 py-4 border-r h-48">
                              <div className="flex flex-col justify-center items-center space-x-4">
                                <div className="transition-transform duration-300 hover:scale-150">
                                  <SmileViewer smileString={item.smiles} imgwidth={150} imgheight={150} />
                                </div>
                                <button
                                  onClick={() => handlePropertiesClick(item.properties, item.smiles)}
                                  className="p-2 mt-2"
                                >
                                  <Info className="ml-4" size={20} />
                                </button>
                              </div>
                            </td>
 
                            {/* Scrollable Details Column */}
                            <td className="px-6 py-4 h-48">
                              <div className="space-y-2">
                                <div className="flex items-center space-x-2">
                                  <span className="font-semibold">Vendor:</span>
                                  <span className="font-bold text-[#735AA7]">
                                    {item.vendors[0]?.vendorName || 'N/A'}
                                  </span>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <span className="font-semibold">Price:</span>
                                  <span>
                                    {item.vendors[0]?.prices[0]?.priceUsd
                                      ? `$${item.vendors[0].prices[0].priceUsd}`
                                      : 'N/A'}
                                  </span>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <span className="font-semibold">Lead Time:</span>
                                  <span>{item.vendors[0]?.leadTime || 'N/A'} days</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <span className="font-semibold">Purchase:</span>
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="p-2 hover:bg-gray-100 rounded-full"
                                  >
                                    <FaExternalLinkAlt className="text-gray-600 hover:text-[#735AA7]" size={18} />
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="flex justify-center items-center py-8 text-gray-500 text-lg font-medium">
                      Data is not available for this compound.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Properties Modal */}
 
      {showPropertiesDialog && (
  <div className="fixed inset-0 z-50 overflow-y-auto">
    {/* Background overlay */}
    <div
      className="fixed inset-0 bg-gray-800 opacity-75"
      aria-hidden="true"
      onClick={() => setShowPropertiesDialog(false)}
    />
 
    {/* Modal panel */}
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg shadow-xl transform transition-all w-3/5 max-h-[80vh] overflow-auto relative">
        {/* Header */}
        <div className="relative p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Molecular Descriptors</h2>
            <button
              onClick={() => setShowPropertiesDialog(false)}
              className="absolute top-5 right-5 bg-red-400 text-white hover:text-gray-600  flex items-center justify-center px-2 py-1 rounded-md"
            >
              <X className='h-6 w-6' />
            </button>
          </div>
 
          <div className="flex justify-center items-center mb-4">
            {selectedSmiles ? (
              <>
              <SmileViewer smileString={selectedSmiles} className="w-auto h-48"/>
              </>
            ) : (
              <p>No SMILES structure available.</p>
            )}
          </div>
 
          <hr />
 
          {/* Properties Table */}
          <div className="border-2 border-gray-300 rounded-lg mt-4">
            <table className="w-full border-collapse uppercase">
              <tbody>
                {selectedProperties ? (
                  (() => {
                    const entries = Object.entries(selectedProperties).filter(
                      ([key]) => key !== 'img'
                    );
                    const half = Math.ceil(entries.length / 2);
                    const leftColumn = entries.slice(0, half);
                    const rightColumn = entries.slice(half);
 
                    return leftColumn.map(([keyLeft, valueLeft], index) => {
                      const [keyRight, valueRight] = rightColumn[index] || [];
                      return (
                        <tr key={index} className="border-b text-sm border-gray-200">
                          {/* Left Column Property */}
                          <td className="py-3 px-4 font-semibold text-gray-700 border-r border-gray-200">
                            {keyLeft}
                          </td>
                          <td className="py-3 px-4 text-right text-gray-900 border-r border-gray-200">
                            {typeof valueLeft === 'number'
                              ? valueLeft.toFixed(3)
                              : valueLeft}
                          </td>
 
                          {/* Right Column Property */}
                          {keyRight ? (
                            <>
                              <td className="py-3 px-4 font-semibold text-gray-700 border-l border-gray-200">
                                {keyRight}
                              </td>
                              <td className="py-3 px-4 text-right text-gray-900">
                                {typeof valueRight === 'number'
                                  ? valueRight.toFixed(3)
                                  : valueRight}
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="py-3 px-4 border-l border-gray-200"></td>
                              <td className="py-3 px-4"></td>
                            </>
                          )}
                        </tr>
                      );
                    });
                  })()
                ) : (
                  <tr>
                    <td colSpan="4" className="py-4 text-center text-gray-500">
                      No properties available for this molecule.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
)}
 

 <div className='mt-10'>
 {selectedSupplier === 'molport' && (
        <MolportAPI
          inputValue={inputValue}
          activeTab={activeTab}
          setFilteredData={setFilteredData}
          selectedSupplier={selectedSupplier}
        />
      )}

 </div>
    
    </div>
  );
}
 
export default RetroVendorScreen;
 
 
 