import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import Modal from 'react-modal';
import { CircleCheck, Gauge, Info, ShoppingCart, Copy, Maximize, HelpCircle, CircleChevronRight, SquarePlus, ClipboardList, Thermometer, Droplet, Percent } from 'lucide-react';
import { GrFormView } from "react-icons/gr";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import SmileViewer from '../smileViewer';
import SmileToName from '../SmileToName';
import { useAtom } from 'jotai';
import { base_api_url, post_api_headers } from "../../utils/Store";
import { toast } from 'sonner';
import { SquareX } from 'lucide-react';
import { Tooltip } from 'react-tooltip';
import '../../styles/editor.css'
import { Plus } from 'lucide-react';
import { ArrowDownToLine, X } from "lucide-react";

Modal.setAppElement('#root');

function RetroSingleTypeResults({ predictionResult, onClose, smileString }) {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedPrediction, setSelectedPrediction] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [yieldPercentage, setYieldPercentage] = useState(null);
    const [reactionConditions, setReactionConditions] = useState(null);
    const [selectedReactantDetails, setSelectedReactantDetails] = useState(null);
    const [loadingConditions, setLoadingConditions] = useState(false)
    const [storedPrediction, setStoredPrediction] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedReactions, setSelectedReactions] = useState([]);
    const baseurl = useAtom(base_api_url)[0];
    const postapi_header = useAtom(post_api_headers)[0];

    // Toggle dropdown visibility
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    // Handle "Select All" functionality
    const handleSelectAll = () => {
        setSelectedReactions(prevSelected =>
            prevSelected.length === predictionResult.result.length
                ? [] // Deselect all if all are already selected
                : predictionResult.result.map((_, index) => index) // Select all
        );
    };

    // Handle checkbox change
    const handleCheckboxChange = (index) => {
        setSelectedReactions(prevSelected => {
            if (prevSelected.includes(index)) {
                return prevSelected.filter(i => i !== index);
            } else {
                return [...prevSelected, index];
            }
        });
    };

    // Load the logo image asynchronously

    const generatePDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
            compress: true
        });

        let yOffset = 40;
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const margin = 20;
        const contentWidth = pageWidth - (2 * margin);

        const toastId = toast.loading('Download is in progress...');

        const loadLogoImage = () => {
            return new Promise((resolve, reject) => {
                const logoImage = new Image();
                logoImage.src = 'https://centellaai-win.azurewebsites.net/static/media/logo.da64b828b56147fd70da.png';
                logoImage.onload = () => resolve(logoImage);
                logoImage.onerror = reject;
            });
        };

        try {
            const logoImage = await loadLogoImage();

            const addPageHeader = (pageNumber) => {
                doc.setFillColor(244, 244, 244); // Light gray color #F4F4F4
                ;
                doc.rect(0, 0, pageWidth, 25, 'F');
                doc.addImage(logoImage, 'PNG', margin, 5, 15, 15);
                doc.setTextColor(0, 0, 0);
                doc.setFontSize(16);
                doc.setFont("helvetica", "bold");
                doc.text("SINGLE-STEP RETROSYNTHESIS RESULTS", pageWidth / 2, 15, { align: 'center' });
                doc.setFontSize(10);
                doc.text(`Page ${pageNumber}`, pageWidth - margin, pageHeight - 10, { align: 'right' });
            };

            for (let i = 0; i < selectedReactions.length; i++) {
                const reactionIndex = selectedReactions[i];
                const reaction = predictionResult.result[reactionIndex];
                const { predicted_reactants, predicted_product } = reaction;

                if (i > 0) {
                    doc.addPage();
                    yOffset = 40;
                }

                addPageHeader(i + 1);

                const reactantStrings = predicted_reactants.map(r => r.string).join('.');
                const reactionString = `${reactantStrings}>>${smileString}`;

                const [conditionsData, yieldData] = await Promise.all([
                    fetch(baseurl + "retro-uat/reaction-conditions", {
                        method: "POST",
                        headers: { ...postapi_header },
                        body: JSON.stringify({ reaction: reactionString }),
                    }).then(res => res.json()),
                    fetch(baseurl + "retro-uat/yield-prediction", {
                        method: "POST",
                        headers: { ...postapi_header },
                        body: JSON.stringify({ sequence: reactionString }),
                    }).then(res => res.json())
                ]);

                const conditions = conditionsData[reactionString];
                const yieldValue = yieldData[reactionString];

                   // Add SMILES string under the reaction details
            doc.setTextColor(70, 70, 70);
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.text(`SMILES: ${smileString}`, margin, yOffset);
            yOffset += 10;


                doc.setFillColor(245, 247, 250);
                doc.roundedRect(margin, yOffset, contentWidth, 15, 3, 3, 'F');
                doc.setTextColor(70, 70, 70);
                doc.setFontSize(14);
                doc.setFont("helvetica", "bold");
                doc.text(`Reaction ${reactionIndex + 1}`, margin + 5, yOffset + 10);
                yOffset += 25;
                

                doc.setFillColor(250, 250, 250);
                doc.roundedRect(margin, yOffset, contentWidth, 30, 2, 2, 'F');
                doc.setDrawColor(230, 230, 230);
                doc.roundedRect(margin, yOffset, contentWidth, 30, 2, 2, 'S');
                doc.setFontSize(10);
                doc.setTextColor(100, 100, 100);
                const conditionsY = yOffset + 8;
                doc.text(`Yield: ${yieldValue ? yieldValue + '%' : 'N/A'}`, margin + 10, conditionsY);
                doc.text(`Temperature: ${conditions ? conditions.Temperature + '°C' : 'N/A'}`, margin + 70, conditionsY);
                doc.text(`Solvents: ${conditions ? conditions["Solvent(s)"] : 'N/A'}`, margin + 10, conditionsY + 12);
                doc.text(`Probability: ${conditions ? conditions.Probability : 'N/A'}`, margin + 70, conditionsY + 12);
                yOffset += 40;

                const reactantWidth = 65;
                const reactantHeight = 65;
                const spacing = (contentWidth - (reactantWidth * 2)) / 3;

                predicted_reactants.forEach((reactant, idx) => {
                    const xPos = margin + spacing + (idx * (reactantWidth + spacing));

                    if (reactant.img) {
                        doc.setDrawColor(200, 200, 200);
                        doc.roundedRect(xPos, yOffset, reactantWidth, reactantHeight, 2, 2, 'S');
                        doc.addImage(reactant.img, 'JPEG', xPos + 2, yOffset + 2, reactantWidth - 4, reactantHeight - 4);
                    }

                    doc.setFontSize(9);
                    doc.setTextColor(70, 70, 70);
                    const label = `Reactant ${idx + 1}`;
                    const labelWidth = doc.getTextWidth(label);
                    doc.text(label, xPos + (reactantWidth - labelWidth) / 2, yOffset + reactantHeight + 8);

                    // Add Reactant Properties
                    const properties = reaction['Reactant properties']?.[reactant.string];
                    if (properties) {
                        let propertyYOffset = yOffset + reactantHeight + 15;
                        Object.entries(properties).forEach(([property, value], index) => {
                            if (property !== 'img') {
                                doc.setFillColor(index % 2 === 0 ? 245 : 255);
                                doc.rect(xPos, propertyYOffset, reactantWidth, 8, 'F');
                                doc.setTextColor(50, 50, 50);
                                doc.setFontSize(8);
                                doc.text(`${property}: ${value || "N/A"}`, xPos + 3, propertyYOffset + 5);
                                propertyYOffset += 8;
                            }
                        });
                    }
                });

                yOffset += reactantHeight + 40;

                if (predicted_product && predicted_product.img) {
                    const productX = pageWidth / 2 - reactantWidth / 2;
                    doc.setDrawColor(130, 180, 70);
                    doc.roundedRect(productX, yOffset, reactantWidth, reactantHeight, 2, 2, 'S');
                    doc.addImage(predicted_product.img, 'JPEG', productX + 2, yOffset + 2, reactantWidth - 4, reactantHeight - 4);
                    doc.setFontSize(9);
                    doc.setTextColor(70, 70, 70);
                    const label = "Product";
                    const labelWidth = doc.getTextWidth(label);
                    doc.text(label, productX + (reactantWidth - labelWidth) / 2, yOffset + reactantHeight + 8);
                }
            }

            doc.save("Retrosynthesis_Results.pdf");
            toast.success('Download completed successfully!', { id: toastId });

        } catch (error) {
            console.error("Error generating PDF:", error);
            toast.error('Failed to download. Please try again.', { id: toastId });
        }
    };



    useEffect(() => {
        if (selectedReactantDetails) {
            fetchYieldPercentage();
        }
    }, [selectedReactantDetails]);

    const fetchYieldPercentage = async () => {
        try {
            const reactantStrings = selectedReactantDetails.predicted_reactants
                .map(reactant => reactant.string)
                .join('.');

            const reactionString = `${reactantStrings}>>${smileString}`;

            const response = await fetch(baseurl + "retro-uat/yield-prediction", {
                method: "POST",
                headers: {
                    ...postapi_header,
                },
                body: JSON.stringify({
                    sequence: reactionString,
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

            // Extract the yield value from the response object
            const yieldValue = data[reactionString]; // Adjust if necessary, depending on API structure
            setYieldPercentage(yieldValue); // Set the yield value in the state
        } catch (error) {
            console.error("Error fetching yield percentage:", error);
        }
    };


    useEffect(() => {
        if (selectedReactantDetails) {
            fetchReactionConditions();
        }
    }, [selectedReactantDetails]);

    const fetchReactionConditions = async () => {
        try {
            setLoadingConditions(true)
            // Construct reactant strings concatenated with a dot
            const reactantStrings = selectedReactantDetails.predicted_reactants
                .map(reactant => reactant.string)
                .join('.');

            // Form the full reaction string
            const reactionString = `${reactantStrings}>>${smileString}`;

            const response = await fetch(baseurl + "retro-uat/reaction-conditions", {
                method: "POST",
                headers: {
                    ...postapi_header,
                },
                body: JSON.stringify({
                    reaction: reactionString
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            const reactionData = data[reactionString];
            setReactionConditions(reactionData);
        } catch (error) {
            console.error("Error fetching reaction conditions:", error);
        } finally {
            setLoadingConditions(false); // End loading for yield percentage
        }
    };




    const handleArrowClick = (prediction, selectedReactantDetails) => {
        setStoredPrediction(prediction);
        setSelectedReactantDetails(prediction);
        setIsPopupOpen(true);
    };







    const closePopup = () => {
        setIsPopupOpen(false); // Close the popup
        setSelectedReactantDetails(null); // Clear reactant details
        setYieldPercentage(null); // Clear yield percentage
        setReactionConditions(null); // Clear reaction conditions
        setLoadingConditions(true); // Reset loading state
    };


    useEffect(() => {
        if (predictionResult && Array.isArray(predictionResult.result)) {
            setSelectedPrediction(predictionResult.result[0]);
            // Log the properties structure to debug
            console.log('Prediction Result:', predictionResult.result[0]);
        }
    }, [predictionResult]);

    const handleImageClick = (item, reactantString, prediction) => {
        // Get properties for the specific reactant
        const properties = prediction['Reactant properties'] &&
            prediction['Reactant properties'][reactantString];

        console.log('Clicked reactant string:', reactantString);
        console.log('Found properties:', properties);

        setSelectedItem(item);
        setSelectedProperties(properties);
        setIsModalOpen(true);
    };

    const handleReactantClick = (reactantString) => {
        navigate('/retrosynthesis/vendor', { state: { reactantString } });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
        setSelectedProperties(null);
    };

    if (!predictionResult || !Array.isArray(predictionResult.result)) {
        return <div>No prediction results available.</div>;
    }
    return (
        <div className="bg-[#F4F4F4] mt-10 p-4 rounded-lg w-full">
            <div className="flex justify-between mt-2 flex-wrap">
                <div className="flex items-center justify-between w-full">
                    <div className="text-lg text-[#2D3748] font-bold">
                        Single-Step Retrosynthetic Route
                    </div>
                    <button

                        onClick={toggleDropdown}
                        className="flex items-center px-3 text-sm text-white py-1 border bg-[#735AA7] hover:bg-[#8A7DB8] rounded-md focus:outline-none"
                    >
                        Download
                        <ArrowDownToLine className="w-4 h-4 ml-1" />
                    </button>
                </div>

                <div className="relative w-full lg:w-auto">
                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-lg shadow-xl z-10"
                            onMouseLeave={() => setIsDropdownOpen(false)} // Close the dropdown on mouse leave
                        >
                            {/* Select All Option */}
                            <div
                                className="flex items-center px-4 py-3 hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                                onClick={handleSelectAll}
                            >
                                <input
                                    type="checkbox"
                                    checked={selectedReactions.length === predictionResult.result.length}
                                    onChange={handleSelectAll}
                                    className="mr-3 text-[#735AA7] focus:ring-0 focus:ring-offset-0 rounded-full"
                                />
                                <span className="text-gray-700 font-medium">Select All</span>
                            </div>

                            {/* Individual Reactions */}
                            {predictionResult.result.map((_, index) => (
                                <div
                                    key={index}
                                    className="flex items-center px-4 py-3 hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
                                    onClick={() => handleCheckboxChange(index)}
                                >
                                    <input
                                        type="checkbox"
                                        checked={selectedReactions.includes(index)}
                                        onChange={() => handleCheckboxChange(index)}
                                        className="mr-3 text-[#735AA7] focus:ring-0 focus:ring-offset-0 rounded-full"
                                    />
                                    <span className="text-gray-700 font-medium">Reaction {index + 1}</span>
                                </div>
                            ))}

                            {/* Download Selected Button */}
                            <button
                                onClick={generatePDF}
                                className={`block w-full text-center px-4 py-3 mt-2 text-white font-semibold bg-[#735AA7] hover:bg-[#8A7DB8] transition-colors duration-200 rounded-b-lg ${selectedReactions.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                disabled={selectedReactions.length === 0}
                            >
                                Download Selected
                            </button>
                        </div>
                    )}
                </div>
            </div>


            <div className="flex flex-wrap lg:flex-nowrap justify-between">
                {predictionResult.result.map((prediction, index) => (
                    <div
                        key={index}
                        className="my-6 bg-white rounded-lg border-gray-400 p-4 shadow-lg w-full lg:w-1/2 xl:w-1/3"
                    >
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center space-x-2">
                                <CircleCheck size={24} fill="#0C9245" color="white" />
                                <div className="text-lg font-semibold">Reaction {index + 1}</div>
                            </div>
                            <button
                                className="p-2 rounded-full text-xs font-semibold cursor-pointer bg-[#735AA7] hover:bg-[#8A7DB8] text-white flex items-center justify-center"
                                onClick={() => handleArrowClick(prediction)}
                                title="Procedure"
                            >
                                Reaction Details
                                <CircleChevronRight className="h-4 w-4 ml-2" />
                            </button>
                        </div>

                        <div className="flex flex-col lg:flex-row items-start lg:items-center  justify-between w-full">
                            {/* Combined Reagents, Reactants, and Products Container */}
                            <div className="w-full lg:flex lg:flex-row items-center space-x-6">
                                {/* Reagents Section */}
                                <div className="md:w-[660px] sm:w-[480px] lg:w-1/2 flex flex-col space-y-4">
                                    <div className="font-semibold text-md mb-4">Reagents</div>
                                    {prediction.predicted_reagents.map((reagent, idx) => (
                                        <div
                                            key={idx}
                                            className="w-44 lg:w-44 sm:w-[95%] md:w-[85%] h-10 border rounded-full bg-[#bbd0ff] flex items-center justify-center relative group"
                                        >
                                            <span className="text-xs cursor-pointer font-semibold truncate uppercase px-1 text-[#004aad]">
                                                <SmileToName smileString={reagent.string} />
                                            </span>
                                        </div>

                                    ))}
                                </div>

                                {/* Reactants and Products Section - Row Layout */}
                                <div className="w-full lg:w-1/2 md:w-[40%] md:pt-6 sm:pt-6 flex flex-row flex-wrap items-center space-x-2 md:space-x-6 mr-4">
                                    {/* Reactants */}
                                    {prediction.predicted_reactants.map((reactant, idx) => (
                                        <React.Fragment key={idx}>
                                            <div className="w-40 h-56 md:w-48 md:h-64 lg:w-56 lg:h-72 text-center border-2 border-dashed border-[#4A90E2] p-4 shadow-md rounded-lg flex flex-col items-center justify-between overflow-hidden mb-4 animate-dashed-border">
                                                <img
                                                    src={reactant.img}
                                                    alt="Reactant"
                                                    className="cursor-pointer w-28 h-28 md:w-36 md:h-36 lg:w-44 lg:h-44 object-contain transition-transform mt-[10%] duration-300 hover:scale-150"
                                                // onClick={() => handleImageClick(reactant.img, reactant.string, prediction)}
                                                />
                                                <span
                                                    className="text-sm font-semibold cursor-pointer uppercase text-[#004aad] relative top-6 sm:mb-6"
                                                    style={{ display: "inline-block", whiteSpace: "nowrap", maxWidth: "120px" }}
                                                // title={reactant.string}
                                                >


                                                    <SmileToName smileString={reactant.string} />
                                                </span>

                                                <div className="flex justify-center space-x-3 sm:mt-2">
                                                    <ShoppingCart
                                                        className="h-5 w-5 hover:text-blue-500 cursor-pointer"
                                                        onClick={() => handleReactantClick(reactant.string)}
                                                        data-tooltip-id="cart-tooltip"  // Tooltip ID for the cart icon
                                                    />
                                                    <Info
                                                        className="cursor-pointer hover:text-blue-500 h-5 w-5"
                                                        onClick={() => handleImageClick(reactant.img, reactant.string, prediction)}
                                                        data-tooltip-id="info-tooltip"  // Tooltip ID for the info icon
                                                    />
                                                    <Copy
                                                        className="cursor-pointer hover:text-blue-500 h-5 w-5"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(reactant.string);
                                                            toast.success("SMILE string copied to clipboard!", {
                                                                className: "mt-[20%]",
                                                            });
                                                        }}
                                                        data-tooltip-id="copy-tooltip"  // Tooltip ID for the copy icon
                                                    />
                                                </div>

                                                {/* Add Tooltip components below your JSX */}
                                                <Tooltip id="cart-tooltip" content="View Vendors" place="top" />
                                                <Tooltip id="info-tooltip" content="View Molecular Descriptors" place="top" />
                                                <Tooltip id="copy-tooltip" content="Copy SMILES" place="top" />
                                            </div>




                                            {idx < prediction.predicted_reactants.length - 1 && (
                                                <div className="flex items-center justify-center h-full">
                                                    <Plus className='w-8 h-8' />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}

                                    {/* Arrow to indicate reaction direction */}
                                    <div className="flex items-center justify-center h-full ">
                                        <FiArrowRight className="h-8 w-8 md:h-8 font-semibold  md:w-8 text-dark cursor-pointer" />
                                    </div>

                                    {/* Products */}
                                    <div className="w-40 h-56 md:w-48 md:h-64 lg:w-56 lg:h-72 text-center border-2 border-dashed animate-dashed-border border-[#80AA3E] p-4 shadow-md rounded-lg flex flex-col items-center justify-between overflow-hidden mb-4">
                                        <div className="transition-transform duration-300 hover:scale-150">
                                            <SmileViewer smileString={smileString} imgwidth={110} imgheight={110} />
                                        </div>

                                        <span className="text-sm font-semibold break-words uppercase text-[#004aad]  truncate">
                                            <SmileToName smileString={smileString} />
                                        </span>
                                        <div className="flex justify-center space-x-3 mt-2">
                                            <Copy
                                                className="cursor-pointer hover:text-blue-500 h-5 w-5"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(smileString);
                                                    toast.success("SMILE string copied to clipboard!", {
                                                        className: "mt-[20%]",
                                                    });
                                                }}
                                                title="Copy"
                                                data-tooltip-id="copy-tooltip"  // Tooltip ID for the copy icon
                                            />

                                        </div>
                                    </div>
                                </div>


                                {/* Popup Panel */}
                                {isPopupOpen && (
                                    <div className="fixed right-2 top-2  border-2  border-[#00000029] rounded-lg h-full bg-white shadow-sm p-6 overflow-y-auto transition-transform transform translate-x-0"
                                        style={{ width: '400px', height: '98.5vh', zIndex: 50 }}>
                                        <div className="flex items-center justify-between mb-4">
                                            <h2 className="text-lg font-bold flex items-center space-x-2">Reactant Details</h2>
                                            <button
                                                onClick={closePopup}
                                                className="p-2 hover:bg-red-50 rounded-full transition-colors duration-200"
                                            >
                                                <X className='h-5 w-5 text-red-500' />
                                            </button>
                                        </div>

                                        {/* Render Reactants Images */}
                                        <div className="flex flex-row space-x-4 items-center space-y-4">
                                            <div className='border-2 border-blue-500 p-4 rounded-lg flex flex-col items-center'>
                                                <div className="flex items-center space-x-12 mb-2">
                                                    {selectedReactantDetails.predicted_reactants.map((reactant, idx) => (
                                                        <div key={idx} className="flex flex-col items-center">
                                                            <img
                                                                src={reactant.img}
                                                                alt='Reactant'
                                                                className='w-32 h-32 object-contain'
                                                            />
                                                            <span className="text-xs mt-1 font-medium text-gray-600">
                                                                Reactant {idx + 1}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Yield Information */}
                                                <div className="flex justify-center gap-6">
                                                    <div className="flex items-center bg-blue-50 px-4 py-2 rounded-lg">
                                                        <Gauge className="w-4 h-4 text-blue-500 mr-2" />
                                                        <div className="flex items-center gap-2">
                                                            <span className="text-xs text-blue-600 font-medium">Yield</span>
                                                            <span className="text-lg font-bold text-blue-700">
                                                                {yieldPercentage !== null ? `${yieldPercentage}%` : "..."}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        {/* Additional Sections */}

                                        {/* Reaction Details Section */}
                                        <div className="bg-white rounded-xl mt-4 border border-gray-200 divide-y divide-gray-100">
                                            <div className="p-4">
                                                <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                                                    <CircleCheck className="w-4 h-4 mr-2 text-[#735AA7]" />
                                                    Reactants
                                                </h3>
                                                <div className="space-y-2 ml-6">

                                                    {selectedReactantDetails.predicted_reactants.map((reactant, idx) => (

                                                        <div key={idx} className="text-sm text-gray-600 flex items-center">
                                                            <div className="w-2 h-2 bg-[#735AA7] rounded-full mr-2"></div>
                                                            <SmileToName smileString={reactant.string} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="p-4">
                                                <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                                                    <HelpCircle className="w-4 h-4 mr-2 text-[#735AA7]" />
                                                    Reaction Conditions
                                                </h3>
                                                {loadingConditions ? (
                                                    <div className="flex items-center justify-center py-4">
                                                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#735AA7]"></div>
                                                    </div>
                                                ) : reactionConditions && (
                                                    <div className="rounded-lg overflow-hidden border border-gray-100">
                                                        {[
                                                            { label: 'Temperature', value: `${reactionConditions.Temperature}°C`, icon: Thermometer },
                                                            { label: 'Solvent', value: reactionConditions["Solvent(s)"], icon: Droplet },
                                                            { label: 'Probability', value: reactionConditions.Probability, icon: Percent }
                                                        ].map((item, idx) => (
                                                            <div
                                                                key={idx}
                                                                className={`flex items-center justify-between p-4
                                        ${idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'} 
                                        transition-colors duration-150 hover:bg-gray-50`}
                                                            >
                                                                <div className="flex items-center space-x-2">
                                                                    <item.icon className="w-4 h-4 text-[#735AA7]" />
                                                                    <span className="text-gray-600">{item.label}</span>
                                                                </div>
                                                                <span className="font-medium text-gray-800">{item.value}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="p-4">
                                                <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                                                    <ClipboardList className="w-4 h-4 mr-2 text-[#735AA7]" />
                                                    Experimental Procedure
                                                </h3>
                                                <div className="text-sm text-gray-500 italic ml-6">
                                                    Currently Under Development
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* Properties Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Molecule Properties"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 1000,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        maxHeight: '80%',
                        overflow: 'auto',
                        borderRadius: '10px',
                        padding: '0',
                    },
                }}
            >
                <div className="relative p-6">     {/* Header with Title */}
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Molecular Descriptors</h2>
                        {/* Close Button */}
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-5 bg-red-400 text-white hover:text-gray-600 px-2 py-1 rounded-md flex items-center justify-center text-sm"
                        >
                            <X className='h-6 w-6' />
                        </button>
                    </div>


                    {/* Properties Table */}
                    <div className="border-2 border-gray-300 rounded-lg">

                        {/* Structure Image */}
                        {selectedItem && (
                            <div className="flex justify-center items-center mb-4">
                                <img
                                    src={selectedItem}
                                    alt="Selected Molecule"
                                    className="w-auto h-48"
                                />
                            </div>
                        )}
                        <hr />
                        <table className="w-full  border-collapse capitalize">
                            <tbody>
                                {selectedProperties ? (
                                    (() => {
                                        const entries = Object.entries(selectedProperties).filter(([key]) => key !== 'img');
                                        const half = Math.ceil(entries.length / 2);
                                        const leftColumn = entries.slice(0, half);
                                        const rightColumn = entries.slice(half);

                                        return leftColumn.map(([keyLeft, valueLeft], index) => {
                                            const [keyRight, valueRight] = rightColumn[index] || [];

                                            return (
                                                <tr key={index} className="border-b text-sm border-gray-200">
                                                    {/* Left Column Property */}
                                                    <td className="py-3 px-4 font-semibold text-gray-700 border-r border-gray-200">
                                                        {keyLeft}
                                                    </td>
                                                    <td className="py-3 px-4 text-right text-gray-900 border-r border-gray-200">
                                                        {typeof valueLeft === 'number' ? valueLeft.toFixed(3) : valueLeft}
                                                    </td>

                                                    {/* Right Column Property */}
                                                    {keyRight ? (
                                                        <>
                                                            <td className="py-3 px-4 font-semibold text-gray-700 border-l border-gray-200">
                                                                {keyRight}
                                                            </td>
                                                            <td className="py-3 px-4 text-right  text-gray-900 border-l border-gray-200 text-gray-900">
                                                                {typeof valueRight === 'number' ? valueRight.toFixed(3) : valueRight}
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td className="py-3 px-4 border-l border-gray-200"></td>
                                                            <td className="py-3 px-4"></td>
                                                        </>
                                                    )}
                                                </tr>
                                            );
                                        });
                                    })()
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="py-4 text-center text-gray-500">
                                            No properties available for this molecule.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default RetroSingleTypeResults;












